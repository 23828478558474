import { Page404Component } from './authentication/page404/page404.component';
import { AuthLayoutComponent } from './layout/app-layout/auth-layout/auth-layout.component';
import { MainLayoutComponent } from './layout/app-layout/main-layout/main-layout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { Role } from './core/models/role';
const routes: Routes = [
  // {
  //   path: '',
  //   component: MainLayoutComponent,
  //   // canActivate: [AuthGuard],
  //   children: [
  //     // { path: '', redirectTo: '/authentication/signin', pathMatch: 'full' },
  //     {
  //       path: 'admin',
  //       canActivate: [AuthGuard],
  //       data: {
  //         role: Role.Admin,
  //       },
  //       loadChildren: () =>
  //         import('./admin/admin.module').then((m) => m.AdminModule),
  //     },


  //     // Extra components
  //     {
  //       path: 'extra-pages',
  //       loadChildren: () =>
  //         import('./extra-pages/extra-pages.module').then(
  //           (m) => m.ExtraPagesModule
  //         ),
  //     }
  //   ],
  // },
  {
    path: 'admin',
    component: MainLayoutComponent,
    children: [
            {
        path: '',
        // canActivate: [AuthGuard],
        // data: {
        //   role: Role.Admin,
        // },
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
      }
    ]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'authentication',
    component: AuthLayoutComponent,
    loadChildren: () =>
      import('./authentication/authentication.module').then(
        (m) => m.AuthenticationModule
      ),
  },
  { path: '**', component: Page404Component },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
