import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [],
})
export class MainLayoutComponent implements OnInit {
  constructor(private router: Router) { }
  href: string = '';
  exceptArray: string[] = ['/','signin'];
  ngOnInit(): void {
    console.log(this.router.url);
    this.href = this.router.url;

  }
}
